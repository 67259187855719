import * as Sentry from '@sentry/react'
import { type CaptureContext } from '@sentry/types'

export const init = () => {
  Sentry.init({
    dsn: 'https://fd03b5760156a51e97bf5e67f819444c@o4505859632463872.ingest.sentry.io/4505859635216384',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', import.meta.env.VITE_DOMAIN],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    debug: import.meta.env.DEV,
  })
}

export const captureException = (
  error: Error,
  captureContext?: CaptureContext,
) => {
  return Sentry.captureException(error, captureContext)
}

export const setUser = (user: Sentry.User) => {
  Sentry.setUser(user)
}
