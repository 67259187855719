/**
 * @description
 * アプリケーション全体のテーマカラーを定義
 * LIFULL Brand Design Guideline参考
 * ref:
 * https://jira.next-group.jp/wiki/display/BDGuideline/Color
 */
export const palette = {
  primary: {
    main: '#ED6103',
    light: '#FEF3EB',
    dark: '#D24E00',
  },
  secondary: {
    main: '#FFAB00',
    light: '#FFF8EB',
    dark: '#D79000',
  },
  text: {
    primary: '#20343f',
    secondary: '#798a94',
    hint: '#a7b8c0',
    disabled: '#c1d0d7',
  },
}

/**
 * @description
 * tailwindのclassNameは動的に組み立てると検出できないのでここで持つ
 * ref: https://tailwindcss.com/docs/content-configuration#class-detection-in-depth
 */
export const tailwindTextColor = {
  textPrimary: 'text-[#20343f]',
  textSecondary: 'text-[#798a94]',
  hint: 'text-[#4B5563]',
  disabled: 'text-[#c1d0d7]',
  primary: `text-[#ED6103]`,
  secondary: `text-[#FFAB00]`,
  error: 'text-red-500',
  black: 'text-black',
  white: 'text-white',
}
