import { type Theme, type Components } from '@mui/material'

/**
 * @description
 * Material-UIのコンポーネント毎のデフォルトprops・styleを拡張する
 * - https://mui.com/material-ui/customization/theme-components/
 */
export const generateComponentsStyle = ({ palette }: Theme): Components => ({
  MuiButton: {
    variants: [
      {
        props: { variant: 'contained', color: 'secondary' },
        style: {
          color: palette.common.white,
        },
      },
    ],
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 600,
      },
      /**
       * @description
       * - Fix icon position
       * https://github.com/mui/material-ui/issues/17712#issuecomment-1207646069
       * - Override Icon fontSize
       * https://github.com/mui/material-ui/issues/28917
       */
      startIcon: {
        position: 'absolute',
        left: '1rem',
        '& > *:first-of-type': {
          fontSize: '1em',
        },
      },
      endIcon: {
        position: 'absolute',
        right: '1rem',
        '& > *:first-of-type': {
          fontSize: '1em',
        },
      },
    },
  },
  MuiMenu: {
    /**
     * @description
     * fix lagging text when open menu
     * https://stackoverflow.com/a/69385787
     */
    defaultProps: { TransitionProps: { timeout: 0 } },
  },
})
