import i18next from 'i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import enTranslation from 'zod-i18n-map/locales/en/zod.json'
import jaTranslation from 'zod-i18n-map/locales/ja/zod.json'

/**
 * @description
 * zodのデフォルトのエラーメッセージ（英語）の日本語化のためi18next使用
 * アプリケーションの多言語化は現状対応しない予定
 */
i18next.init({
  lng: 'ja',
  resources: {
    ja: { zod: jaTranslation },
    en: { zod: enTranslation },
  },
})
z.setErrorMap(zodI18nMap)
