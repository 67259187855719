import { tv } from 'tailwind-variants'

import { typography, tailwindTextColor } from '@/components/styles/themes'

/**
 * @description
 * アプリケーションのTypography定義からMuiのTypographyに変換
 */
const tailwindTypography = Object.fromEntries(
  Object.entries(typography).map(([property, styles]) => {
    const { tailwindClassName } = styles
    return [property, tailwindClassName]
  }),
)

/**
 * @description
 * テキストのスタイルを呼び出せるユーティリティ
 * - プロパティ名はMaterial-UIのtheme準拠、styleもMaterial-UI:typographyのvariantと同期させている
 */
type TwText = ({
  variant,
  color,
}: {
  variant?: string
  color?: string
}) => string
export const twText: TwText = tv(
  {
    variants: {
      variant: tailwindTypography,
      color: tailwindTextColor,
    },
    defaultVariants: {
      variant: 'body1',
      color: 'textPrimary',
    },
  },
  { twMergeConfig: { cacheSize: 0 } },
)
