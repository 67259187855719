import { Auth0Provider } from '@auth0/auth0-react'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { type FC, type ReactNode, useEffect } from 'react'
import { Outlet, ScrollRestoration, useNavigate, Navigate } from 'react-router-dom'

import { MuiStyleProvider } from '@/components/styles/mui/MuiStyleProvider'
import { AuthProvider } from '@/contexts/AuthContext'
import { BackdropProvider } from '@/contexts/BackdropContext'
import { MessageProvider } from '@/contexts/MessageContext'
import { usePageTracking } from '@/core/analytics'
import {
  QueryClient,
  QueryClientProvider,
  ReactQueryDevtools,
} from '@/lib/react-query'

/**
 * initialize library
 */
import '@/core/validator/zod'
import '@/core/error'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 300000,
    },
  },
})

// 下に行くほど、内側で使われる
const providers: Array<FC<{ children: ReactNode }>> = [
  ({ children }) => (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools position="top-right" />
    </QueryClientProvider>
  ),
  ({ children }) => <MuiStyleProvider>{children}</MuiStyleProvider>,
  ({ children }) => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {children}
    </LocalizationProvider>
  ),
  ({ children }) => <MessageProvider>{children}</MessageProvider>,
  ({ children }) => <BackdropProvider>{children}</BackdropProvider>,
  ({ children }) => {
    const navigate = useNavigate()
    return (
      <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: import.meta.env.VITE_AUTH0_API_AUDIENCE,
        }}
        onRedirectCallback={(appState) => {
          navigate(appState?.returnTo ?? window.location.pathname)
        }}
      >
        {children}
      </Auth0Provider>
    )
  },
  ({ children }) => <AuthProvider>{children}</AuthProvider>,
]

const reverseProviders = providers.reverse()
const Providers: FC<{ children: ReactNode }> = ({ children }) => (
  <>
    {reverseProviders.reduce(
      (node, Provider) => (
        <Provider>{node}</Provider>
      ),
      children,
    )}
  </>
)

const App: React.FC = () => {
  useEffect(() => {
    const path = window.location.pathname
    if (path === '/usecases/recommended_city_ai_support') {
      window.location.href = "https://trial.dxp.homes.co.jp/usecases/recommended_property_ai_support"
      return
    }
    // https://trial.dxp.homes.co.jp にリダイレクト
    window.location.href = `https://trial.dxp.homes.co.jp${path}`;
  }, []);
  usePageTracking()

  return (
    null
  )
}

export default App
