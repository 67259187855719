import {
  useState,
  createContext,
  useContext,
  type ReactNode,
  createRef,
  useImperativeHandle,
} from 'react'

import { type ApiError } from '@/client'
import {
  type MessageSnackbarProps,
  MessageSnackbar,
} from '@/components/organisms/message/Snackbar'
import { captureException } from '@/core/error'

type MessageContext = {
  showError: (e: Error | ApiError | string) => number
  showMessage: (
    message: string,
    severity?: MessageSnackbarProps['severity'],
    action?: MessageSnackbarProps['action'],
  ) => number
}

const Context = createContext({} as MessageContext)

/**
 * @description
 * use for handle show error/message outside component
 *
 * @example
 * messageRef.current?.showError()
 * messageRef.current?.showMessage()
 */
export const messageRef = createRef<MessageContext>()

/**
 * @description
 * アプリケーション共通のメッセージ表示用Snackbarを呼び出すhook
 * 各々の関数を呼び出すと画面下部にSnackbarメッセージが表示される
 * - showMessage 通常メッセージ表示用
 * - showError エラーメッセージ表示用
 */
export function useMessage() {
  return useContext(Context)
}

let uniqueId = 1

type MessageProps = {
  id: number
  message: string
  open: boolean
  severity: MessageSnackbarProps['severity']
  action?: MessageSnackbarProps['action']
}
export const MessageProvider = ({ children }: { children: ReactNode }) => {
  const [{ current }, setState] = useState<{
    current: MessageProps | null
    queue: MessageProps[]
  }>({
    current: null,
    queue: [],
  })

  useImperativeHandle(messageRef, () => ({
    showError,
    showMessage,
  }))

  const showError = (e: Error | string) => {
    const message = convertErrorMessage(e)

    const id = uniqueId++
    const snack: MessageProps = { id, message, open: true, severity: 'error' }

    setState(({ current, queue }) =>
      current != null
        ? { current, queue: queue.concat(snack) }
        : { current: snack, queue },
    )

    return id
  }

  const showMessage = (
    message: string,
    severity: MessageSnackbarProps['severity'] = 'success',
    action?: MessageSnackbarProps['action'],
  ) => {
    const id = uniqueId++
    const snack: MessageProps = { id, message, open: true, severity, action }

    setState(({ current, queue }) =>
      current != null
        ? { current, queue: queue.concat(snack) }
        : { current: snack, queue },
    )

    return id
  }

  const handleClose = () => {
    setState((currentState) => ({
      ...currentState,
      current: { ...(currentState.current as MessageProps), open: false },
    }))
    // time to snack close animation
    setTimeout(openNext, 1000)
  }

  const openNext = () => {
    setState(({ current, queue }) =>
      queue.length > 0
        ? { current: queue[0], queue: queue.slice(1) }
        : { current: null, queue: [] },
    )
  }

  return (
    <Context.Provider value={{ showError, showMessage }}>
      {current != null && (
        <MessageSnackbar
          {...current}
          key={current.id}
          onClose={handleClose}
          autoHideDuration={null}
        />
      )}
      {children}
    </Context.Provider>
  )
}

/**
 * @description
 * エラーオブジェクトに応じたエラーメッセージをUIへの表示用文言に整形して返す。
 * @returns {string} message
 */
function convertErrorMessage(e: Error | ApiError | string) {
  if (typeof e === 'string') {
    captureException(new Error(e))

    return e
  }

  captureException(e)

  /**
   * TypeError: Failed to fetch
   */
  if (e.message === 'Failed to fetch')
    return 'ネットワークエラーが発生しました。お時間をおいて再度お試し下さい。'

  /**
   * @description
   * @/clientで自動生成されたクエリの場合、statusにコードが付与されるため対応したメッセージを表示
   */
  if (
    'status' in e &&
    Object.keys(errorMessagesFromStatusCode).includes(String(e.status))
  )
    return errorMessagesFromStatusCode[e.status as StatusCode]

  return e.message
}

type StatusCode = keyof typeof errorMessagesFromStatusCode
const errorMessagesFromStatusCode = {
  400: '要求の形式が正しくありません。管理者にお問い合わせください。',
  401: 'アクセス権限がありません。管理者にお問い合わせください。',
  403: 'アクセス許可がありません。管理者にお問い合わせください。',
  404: 'このURLは存在しません。管理者にお問い合わせください。',
  500: 'サーバー内部エラーが発生しました。管理者にお問い合わせください。',
  502: 'ゲートウェイに問題が発生しました。管理者にお問い合わせください。',
  503: 'サービスが一時的に利用できません。管理者にお問い合わせください。',
} as const
