import {
  type FC,
  type ReactNode,
  useCallback,
  useState,
  createContext,
} from 'react'

import SimpleBackdrop from '@/components/atomic/Backdrop'

export interface backdropContextType {
  open: () => void
  openClickClose: () => void
  close: () => void
}

export const BackdropContext = createContext<backdropContextType>(
  undefined as never,
)

export const BackdropProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [backdrop, setBackdrop] = useState(false)
  const [onClose, setOnClose] = useState<() => void>(() => {})

  const open = useCallback(() => {
    setBackdrop(true)
  }, [])

  const close = useCallback(() => {
    setBackdrop(false)
    setOnClose(() => {})
  }, [])

  const openClickClose = useCallback(() => {
    setBackdrop(true)
    setOnClose(close)
  }, [close])

  return (
    <BackdropContext.Provider value={{ open, openClickClose, close }}>
      {children}
      <SimpleBackdrop open={backdrop} onClose={onClose} />
    </BackdropContext.Provider>
  )
}
