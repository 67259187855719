/**
 * @description
 * アプリケーション全体の文字形式を定義
 * - ベースのスタイルはTailwindCSSのtextクラス準拠
 * - variant名はMaterial-UIのTypography variant準拠
 *
 * - tailwindのclassNameは動的に組み立てると検出できないのでここで持つ
 *   ref: https://tailwindcss.com/docs/content-configuration#class-detection-in-depth
 */
export const typography = {
  /**
   * tailwind text-4xl bold
   */
  h1: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '40px',
    tailwindClassName: 'text-4xl font-bold',
  },
  /**
   * tailwind text-3xl bold
   */
  h2: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '36px',
    tailwindClassName: 'text-3xl font-bold',
  },
  /**
   * tailwind text-2xl bold
   */
  h3: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '32px',
    tailwindClassName: 'text-2xl font-bold',
  },
  /**
   * tailwind text-xl bold
   */
  h4: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '28px',
    tailwindClassName: 'text-xl font-bold',
  },
  /**
   * tailwind text-lg bold
   */
  h5: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '28px',
    tailwindClassName: 'text-lg font-bold',
  },
  /**
   * tailwind text-base bold
   */
  h6: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    tailwindClassName: 'text-base font-bold',
  },
  /**
   * tailwind text-sm bold
   */
  subtitle1: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    tailwindClassName: 'text-sm font-bold',
  },
  /**
   * tailwind text-xs bold
   */
  subtitle2: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px',
    tailwindClassName: 'text-xs font-bold',
  },
  /**
   * tailwind text-base normal
   */
  body1: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    tailwindClassName: 'text-base',
  },
  /**
   * tailwind text-sm normal
   */
  body2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    tailwindClassName: 'text-sm',
  },
  body3: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    tailwindClassName: 'text-xs',
  },
  /**
   * tailwind text-sm line-height text-large bold
   */
  button: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '28px',
    tailwindClassName: 'text-sm font-bold',
  },
  /**
   * custom xxs bold
   */
  caption: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '16px',
    tailwindClassName: 'text-[10px] font-bold leading-[16px]',
  },
  /**
   * custom xxxs bold
   */
  overline: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '8px',
    lineHeight: '10px',
    tailwindClassName: 'text-[8px] font-bold leading-[10px]',
  },
}

/**
 * @description
 * 自前で用意したwebフォントのフォントファミリー名
 */
export const customFontFamilyNames = ['LIFULLFont', 'Yu Gothic', 'YuGothic']
