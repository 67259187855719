import { type FC } from 'react'
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'

import App from './App'
import BlankChat from './pages/chat/BlankChat'
import Chat from './pages/chat/Chat'
import ChatRoute from './pages/chat/ChatRoute'
import ContextChat from './pages/contexts/chat/ContextChat'
import ContextChatSession from './pages/contexts/chat/ContextChatSession'
import ContextsRoute from './pages/contexts/ContextsRoute'
import ContextsView from './pages/contexts/ContextsView'
import Usecase from './pages/usecases/Usecase'

import SideWithMenu from '@/components/layouts/SideWithMenu'
import Context from '@/pages/contexts/Context'
import ContextRoute from '@/pages/contexts/ContextRoute'
import Help from '@/pages/helps/Help'
import HelpRoute from '@/pages/helps/HelpRoute'
import HelpsTop from '@/pages/helps/HelpsTop'
import Loading from '@/pages/Loading'
import Login from '@/pages/Login'
import Main from '@/pages/Main'
import NotFound from '@/pages/NotFound'
import TermsOfUse from '@/pages/TermsOfUse'
import Usage from '@/pages/Usage'
import AudioToSummarizeText from '@/pages/usecases/AudioToSummarizeText'
import TranscribeBySpeechStudio from '@/pages/usecases/TranscribeBySpeechStudio'
import TranscribeBySpeechStudioResult from '@/pages/usecases/TranscribeBySpeechStudioResult'
import UsecasesRoute from '@/pages/usecases/UsecaseRoute'
import UsecasesTop from '@/pages/usecases/UsecasesTop'

export enum Paths {
  CONTEXT = 'contexts',
  CHAT = 'chat',
  SAMPLE = 'samples',
  HELP = 'helps',
  USECASE = 'usecases',
  USAGE = 'usage',
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="*" element={<App />} />
    </Route>,
  ),
  { basename: '/' },
)

const Router: FC = () => {
  return <RouterProvider router={router} />
}

export default Router
