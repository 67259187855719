import { init, captureException, setUser } from '@/lib/sentry'

/**
 * @description
 * アプリケーションのエラー監視ラッパー（現在はSentryを使用）
 */

init()

export { captureException, setUser }
