import { type ThemeOptions } from '@mui/material'

import {
  palette,
  typography,
  customFontFamilyNames,
} from '@/components/styles/themes'

/**
 * @description
 * アプリケーションのTypography定義からMuiのTypographyに変換
 */
const muiTypography = Object.fromEntries([
  ...Object.entries(typography).map(([property, styles]) => {
    const { tailwindClassName, ...muiStyles } = styles
    return [property, muiStyles]
  }),
  [
    'fontFamily',
    [
      ...customFontFamilyNames,
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  ],
])

/**
 * @description
 * Material-UIのデフォルトの全体テーマをアプリケーション用にカスタムしてoverrideする
 * - https://mui.com/material-ui/customization/theming/
 */
export const defaultTheme: ThemeOptions = {
  palette,
  typography: muiTypography,
  /**
   * @description
   * Tailwind CSSのデフォルト設定に準拠
   * - https://tailwindcss.com/docs/screens
   */
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
}
