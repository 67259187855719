import {
  ThemeProvider,
  createTheme as createMuiTheme,
  CssBaseline,
} from '@mui/material'
import { type FC, type ReactNode } from 'react'

import { defaultTheme, generateComponentsStyle } from './themes'

/**
 * @description
 * Material-UIのコンポーネントのデフォルト機能をアプリケーション内のテーマ定義で拡張
 */
const createTheme = () => {
  return createMuiTheme(defaultTheme, {
    components: generateComponentsStyle(createMuiTheme(defaultTheme)),
  })
}

export const theme = createTheme()

export const MuiStyleProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
