import { useAuth0, type User } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import {
  createContext,
  type ReactNode,
  useContext,
  type FC,
  useState,
  useCallback,
  useEffect,
} from 'react'

import { type CoreApi } from '@/client'
import { setUser as setUserAnalytics } from '@/core/analytics'
import { setUser } from '@/core/error'
import { useClient } from '@/core/useClient'
import { useQuery } from '@/lib/react-query'

export interface AuthContextType {
  api: CoreApi
  user?: User
  logout: () => void
  isLogin: boolean
}

const AuthContext = createContext<AuthContextType>(undefined as never)

export const useAuthContext = () => {
  return useContext(AuthContext)
}

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const {
    user,
    getAccessTokenSilently,
    logout: auth0Logout,
    isAuthenticated,
  } = useAuth0()
  const { getApiClient } = useClient()
  const [api, setApi] = useState(getApiClient())
  const [isLogin, setIsLogin] = useState(false)
  const [token, setToken] = useState<string | undefined>()

  useQuery(
    ['token', getAccessTokenSilently, isAuthenticated],
    async () => {
      if (!isAuthenticated) {
        return ''
      }
      const token = await getAccessTokenSilently()
      return token
    },
    {
      onSuccess: (token) => {
        setToken(token)
      },
    },
  )

  useEffect(() => {
    if (!(token != null && token?.length > 0)) {
      setIsLogin(false)
      return
    }
    setApi(getApiClient(token))
    setIsLogin(true)
  }, [token, getApiClient, setApi, setIsLogin])

  useEffect(() => {
    if (user == null) return

    setUser({ ...user, id: user?.sub ?? 'unknown' })
    setUserAnalytics(user?.sub ?? 'unknown')
  }, [user])

  const logout = useCallback(() => {
    setApi(getApiClient())
    auth0Logout({
      logoutParams: { returnTo: window.location.origin },
    })
  }, [getApiClient, auth0Logout])

  return (
    <AuthContext.Provider value={{ api, user, logout, isLogin }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
