/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type V1ChatParam = {
  /**
   * モデル
   */
  model?: V1ChatParam.model
  /**
   * サンプリング温度は0~2の間で指定します。0.8のような高い値は出力をよりランダムにし、0.2のような低い値は出力をより集中的に決定的にします。一般的には、この変更かtop_pの変更をお勧めしますが、両方はお勧めしません。
   */
  temperature?: number
  /**
   * 温度によるサンプリングに代わるものとして、核サンプリングと呼ばれる、確率質量がtop_pのトークンの結果を考慮するモデルがある。つまり、0.1は上位10%の確率質量からなるトークンだけを考慮することを意味します。
   */
  top_p?: number
  /**
   * 各入力メッセージに対して、チャット補完の選択肢をいくつ生成するか。
   */
  n?: number
  /**
   * 生成される答えに許可されるトークンの最大数です。デフォルトでは、モデルが返すことのできるトークン数は(4096 - prompt tokens)となります。
   */
  max_tokens?: number
  /**
   * -2.0から2.0の間の数値。正の値は、新しいトークンがこれまでのテキストに現れたかどうかに基づいてペナルティを与え、モデルが新しいトピックについて話す可能性を高める。
   */
  presence_penalty?: number
  /**
   * 2.0から2.0の間の数値。正の値は、これまでのテキストにおける既存の頻度に基づいて新しいトークンにペナルティを与え、モデルが同じ行をそのまま繰り返す可能性を減少させる。
   */
  frequency_penalty?: number
  /**
   * 指定したトークンが補完に登場する可能性を変更する。トークン(トークナイザーのトークンIDで指定)を-100~100のバイアス値にマッピングするjsonオブジェクトを受け取ります。数学的には、バイアスはサンプリング前にモデルによって生成されたロジットに追加されます。正確な効果はモデルによって異なりますが、-1~1の値は選択の可能性を減少または増加させるもので、-100や100のような値は関連するトークンを禁止または排他的に選択する結果となります。
   */
  logit_bias?: Record<string, number>
}

export namespace V1ChatParam {
  /**
   * モデル
   */
  export enum model {
    GPT_4 = 'gpt-4',
    GPT_4_0314 = 'gpt-4-0314',
    GPT_4_32K = 'gpt-4-32k',
    GPT_4_32K_0314 = 'gpt-4-32k-0314',
    GPT_3_5_TURBO = 'gpt-3.5-turbo',
    GPT_3_5_TURBO_0301 = 'gpt-3.5-turbo-0301',
  }
}
