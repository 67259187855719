import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

const measurementId = import.meta.env.VITE_GA_MEASUREMENT_ID

export const init = () => {
  if (measurementId === '' || measurementId === undefined)
    throw new Error('GAのMeasurement IDが設定されていません。')

  ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID)
}

init()

export const setUser = (userId?: string) => {
  if (measurementId === '' || measurementId === undefined)
    throw new Error('GAのMeasurement IDが設定されていません。')

  /**
   * @description
   * GA dashboardでcustom dimensionsを設定
   * https://github.com/codler/react-ga4/issues/7
   * https://support.google.com/analytics/answer/12370404?sjid=100722981981034606-AP#zippy=%2Cgoogle-%E3%82%BF%E3%82%B0%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88
   */
  ReactGA.gtag('config', import.meta.env.VITE_GA_MEASUREMENT_ID, {
    user_properties: {
      userId,
    },
  })
}

export const pageview = (path: string) => {
  ReactGA.send({ hitType: 'pageview', page: path })
}

export const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: location.pathname,
    })
  }, [location])
}
